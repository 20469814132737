<script setup lang="ts">
import type { DropdownItem } from "#ui/types";
const { t, locale } = useI18n();
const localePath = useLocalePath();

const { user: currentUser, isLoading } = useUser(true);

const eventPassesActionsRequired = ref(false);

const userFullName = computed(() => {
  if (isLoading.value) return undefined;
  if (!currentUser?.value?.first_name) return undefined;

  return `${currentUser?.value?.first_name} ${currentUser?.value?.last_name}`;
});

const items = ref<DropdownItem[][]>([]);

const setItems = () => {
  items.value = [
    [
      {
        label: userFullName,
        slot: "account",
        disabled: true,
      },
      {
        label: t("my_account"),
        icon: "i-heroicons-user-circle",
        to: localePath("/account"),
      },
    ],
    [
      {
        label: t("passes"),
        icon: "i-heroicons-star",
        to: localePath("/passes"),
        actionsRequired: eventPassesActionsRequired.value,
      },
      {
        label: t("purchase_history"),
        icon: "i-heroicons-clipboard-document-list",
        to: localePath("/orders"),
      },
    ],
    [
      {
        label: t("sign_out"),
        icon: "i-heroicons-arrow-right-on-rectangle",
        click: () => window.location.assign("/api/auth/logout"),
      },
    ],
  ] as DropdownItem[][];
};

watch(locale, setItems);

setItems();
</script>

<template>
  <div class="flex items-center">
    <u-dropdown
      :items="items"
      :ui="{ item: { disabled: 'cursor-text select-text' } }"
      :popper="{ placement: 'bottom-end' }"
    >
      <u-chip v-if="eventPassesActionsRequired" inset>
        <u-avatar
          :src="!isLoading && currentUser.avatar_url"
          :alt="userFullName"
          img-class="object-cover"
        />
      </u-chip>

      <u-avatar
        v-else
        :src="!isLoading && currentUser.avatar_url"
        :alt="userFullName"
        img-class="object-cover"
      />

      <template #account="{ item }">
        <div class="text-left">
          <p>Signed in as</p>
          <u-skeleton v-if="isLoading" class="h-5 w-16" />
          <p v-else class="truncate font-medium text-gray-900 dark:text-white">
            {{ item.label }}
          </p>
        </div>
      </template>
      <template #item="{ item }">
        <div class="flex justify-between w-full">
          <span class="truncate">{{ item.label }}</span>
          <u-chip v-if="item.actionsRequired">
            <u-icon
              :name="item.icon"
              class="flex-shrink-0 h-4 w-4 text-gray-400 dark:text-gray-500 ms-auto"
            />
          </u-chip>

          <u-icon
            v-else
            :name="item.icon"
            class="flex-shrink-0 h-4 w-4 text-gray-400 dark:text-gray-500 ms-auto"
          />
        </div>
      </template>
    </u-dropdown>
  </div>
</template>
