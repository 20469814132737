import { type QueryClientConfig, useQuery } from "@tanstack/vue-query";
import { invalidateUserData } from "./useUser.composable";

export const USE_SESSION_QUERY_KEY = "useSession";

export enum SessionStatus {
  LOADING = "loading",
  AUTHENTICATED = "authenticated",
  UNAUTHENTICATED = "unauthenticated",
}

export function useSession(
  forceLogin = false,
  redirect = "/login",
  queryConfig?: QueryClientConfig,
) {
  const router = useRouter();

  const session = useQuery<boolean>({
    queryKey: [USE_SESSION_QUERY_KEY],
    queryFn: async () => {
      try {
        const accessToken = useCookie("access_token");
        const refreshToken = useCookie("refresh_token");

        await $fetch("/api/auth/refresh", {
          method: "GET",
          headers: {
            cookie: `access_token=${accessToken.value}; refresh_token=${refreshToken.value}`,
          },
        });

        return true;
      } catch {
        if (forceLogin) {
          navigateTo(`${redirect}?redirect=${window.location.pathname}`, {
            external: true,
          });
        }
        await invalidateUserData().catch((e) => console.error(e));

        return false;
      }
    },

    retry: 1,
    staleTime: 1000 * 60 * 5,
    refetchInterval: 1000 * 60 * 1,
    ...queryConfig,
  });

  const authStatus = computed(() => {
    if (session.isLoading.value) return SessionStatus.LOADING;
    if (session.data.value === true) return SessionStatus.AUTHENTICATED;

    return SessionStatus.UNAUTHENTICATED;
  });

  // EDGE CASE: User is forced to login AFTER the session has been fetched (but not yet invalidated)
  watchEffect(() => {
    if (forceLogin && authStatus.value === SessionStatus.UNAUTHENTICATED)
      router.push(redirect);
  });

  return {
    status: authStatus,

    refetch: session.refetch,
    isLoading: session.isLoading,
    isLoadingError: session.isLoadingError,
    isPending: session.isPending,

    query: session,
  };
}
