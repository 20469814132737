<script setup lang="ts">
import { VueQueryDevtools } from "@tanstack/vue-query-devtools";

interface ErrorObject {
  url: string;
  statusCode: number;
  statusMessage: string;
  message: string;
  description: string;
  data: any;
}

definePageMeta({
  auth: false,
});

const props = defineProps({
  error: {
    type: Object as PropType<ErrorObject>,
    required: true,
  },
});

console.error(props.error);

const resetAndGoToHome = () => clearError({ redirect: "/" });
</script>

<template>
  <div>
    <toolbar-without-sidebar />

    <div
      class="flex w-full flex-col gap-8 items-center mt-32 container mx-auto px-10 text-center"
    >
      <error-message
        v-if="props.error.statusCode === 404"
        image-src="/images/nerdywoffy_idk.png"
        header="Page not found"
      >
        <p>
          Welp, looks like you're lost. The page you're looking for doesn't
          exist. You sure you've visited this page before?
        </p>
      </error-message>

      <error-message
        v-else-if="props.error.statusCode === 401"
        header="Hold on there. Where are you going!?"
        image-src="/images/darrellwoof_poker_face.png"
      >
        <p>
          Who are you? You're a bit sus. You're not supposed to be here. Please
          log in.
        </p>
      </error-message>

      <error-message
        v-else-if="props.error.statusCode === 403"
        header="Hush. No entry."
        image-src="/images/nerdywoffy_stop.png"
      >
        <p>
          Hold up. You're not supposed to do that. You don't have the permission
          to access that resource.
        </p>
      </error-message>

      <error-message
        v-else-if="props.error.statusCode === 418"
        header="I'm a teapot!"
        image-src="/images/nerdywoffy_teapot.png"
      >
        <p>
          I'm a little teapot, short and stout. Here is my handle, here is my
          spout. When I get all steamed up, hear me shout. Tip me over and pour
          me out!
        </p>
      </error-message>

      <error-message
        v-else-if="props.error.statusCode === 413"
        header="Request too large"
        image-src="/images/kurarifox_too_large.png"
      >
        <p>
          Whoa! That's a lot of data. We can't handle that much data. Please try
          again with a smaller request.
        </p>
      </error-message>

      <error-message
        v-else
        header="Whoops! Something went a bit wonky."
        image-src="/images/nerdywoffy_server_fire.png"
      >
        <p>
          But no worries! Our team has been notified and we'll have things back
          to normal in a jiffy!
        </p>
      </error-message>

      <u-button @click="resetAndGoToHome">Back Home</u-button>
    </div>
  </div>

  <vue-query-devtools />
</template>
