export default defineAppConfig({
  ui: {
    primary: "pink",
    gray: "slate",
    skeleton: {
      base: "animate-pulse",
      background: "bg-gray-200 dark:bg-gray-800",
      rounded: "rounded-md",
    },
    notifications: {
      // Show toasts at the top right of the screen
      position: "top-0 bottom-auto",
    },
    avatar: {
      size: {
        "5xl": "h-28 w-28",
        "6xl": "h-32 w-32 text-2xl",
      },
    },
  },
});
