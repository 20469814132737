import { default as about_45communityu9L6pNbZHmMeta } from "/app/src/pages/about-community.vue?macro=true";
import { default as emergency_45contactsSQNXToG3npMeta } from "/app/src/pages/account/emergency-contacts.vue?macro=true";
import { default as index0nd1weGJHvMeta } from "/app/src/pages/account/index.vue?macro=true";
import { default as personal_45informationbrrvaYuib9Meta } from "/app/src/pages/account/personal-information.vue?macro=true";
import { default as profilexC9kKicAinMeta } from "/app/src/pages/account/profile.vue?macro=true";
import { default as account6KhWjDO4SsMeta } from "/app/src/pages/account.vue?macro=true";
import { default as _91_91slug_93_93n0fchBfiQgMeta } from "/app/src/pages/events/[id]/[[slug]].vue?macro=true";
import { default as indexhIZIxDAPTgMeta } from "/app/src/pages/events/index.vue?macro=true";
import { default as _91_46_46_46slug_93FtFwvw1MCBMeta } from "/app/src/pages/help/[...slug].vue?macro=true";
import { default as contacthbEE5oOxhIMeta } from "/app/src/pages/help/contact.vue?macro=true";
import { default as help6sYsgUQJxrMeta } from "/app/src/pages/help.vue?macro=true";
import { default as index4fwwccb5ksMeta } from "/app/src/pages/host/index.vue?macro=true";
import { default as redeem_45passesRyMvv4fdBeMeta } from "/app/src/pages/host/redeem-passes.vue?macro=true";
import { default as indexdPwi6hPdMfMeta } from "/app/src/pages/index.vue?macro=true";
import { default as index1f3Z4n7k2iMeta } from "/app/src/pages/login/index.vue?macro=true";
import { default as checkoutgMc4Wmt3ETMeta } from "/app/src/pages/orders/[sid]/checkout.vue?macro=true";
import { default as indexsQLAIQ7Ce9Meta } from "/app/src/pages/orders/[sid]/index.vue?macro=true";
import { default as personal_45informationbosj6jfbGNMeta } from "/app/src/pages/orders/[sid]/personal-information.vue?macro=true";
import { default as terms07WKDEhy2RMeta } from "/app/src/pages/orders/[sid]/terms.vue?macro=true";
import { default as _91sid_93cAifiDcS5qMeta } from "/app/src/pages/orders/[sid].vue?macro=true";
import { default as callbacknH01rHiJOcMeta } from "/app/src/pages/orders/callback.vue?macro=true";
import { default as indexRgJBGWiq26Meta } from "/app/src/pages/orders/index.vue?macro=true";
import { default as additional_45informationSYbLWFmKySMeta } from "/app/src/pages/passes/[id]/additional-information.vue?macro=true";
import { default as indexExkcY2djeQMeta } from "/app/src/pages/passes/[id]/index.vue?macro=true";
import { default as managementBEQCwIMLURMeta } from "/app/src/pages/passes/[id]/management.vue?macro=true";
import { default as _91id_93rmewM08fn2Meta } from "/app/src/pages/passes/[id].vue?macro=true";
import { default as indexIMI2zd05arMeta } from "/app/src/pages/passes/index.vue?macro=true";
import { default as teapotIf49sYKQG2Meta } from "/app/src/pages/teapot.vue?macro=true";
import { default as component_45stubnO9MU04yTUMeta } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubnO9MU04yTU } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "about-community___en",
    path: "/about-community",
    component: () => import("/app/src/pages/about-community.vue").then(m => m.default || m)
  },
  {
    name: "about-community___id",
    path: "/id/about-community",
    component: () => import("/app/src/pages/about-community.vue").then(m => m.default || m)
  },
  {
    name: account6KhWjDO4SsMeta?.name,
    path: "/account",
    meta: account6KhWjDO4SsMeta || {},
    component: () => import("/app/src/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: "account-emergency-contacts___en",
    path: "emergency-contacts",
    component: () => import("/app/src/pages/account/emergency-contacts.vue").then(m => m.default || m)
  },
  {
    name: "account___en",
    path: "",
    component: () => import("/app/src/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-personal-information___en",
    path: "personal-information",
    component: () => import("/app/src/pages/account/personal-information.vue").then(m => m.default || m)
  },
  {
    name: "account-profile___en",
    path: "profile",
    component: () => import("/app/src/pages/account/profile.vue").then(m => m.default || m)
  }
]
  },
  {
    name: account6KhWjDO4SsMeta?.name,
    path: "/id/account",
    meta: account6KhWjDO4SsMeta || {},
    component: () => import("/app/src/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: "account-emergency-contacts___id",
    path: "emergency-contacts",
    component: () => import("/app/src/pages/account/emergency-contacts.vue").then(m => m.default || m)
  },
  {
    name: "account___id",
    path: "",
    component: () => import("/app/src/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-personal-information___id",
    path: "personal-information",
    component: () => import("/app/src/pages/account/personal-information.vue").then(m => m.default || m)
  },
  {
    name: "account-profile___id",
    path: "profile",
    component: () => import("/app/src/pages/account/profile.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "events-id-slug___en",
    path: "/events/:id()/:slug?",
    meta: _91_91slug_93_93n0fchBfiQgMeta || {},
    component: () => import("/app/src/pages/events/[id]/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "events-id-slug___id",
    path: "/id/events/:id()/:slug?",
    meta: _91_91slug_93_93n0fchBfiQgMeta || {},
    component: () => import("/app/src/pages/events/[id]/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "events___en",
    path: "/events",
    meta: indexhIZIxDAPTgMeta || {},
    component: () => import("/app/src/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: "events___id",
    path: "/id/events",
    meta: indexhIZIxDAPTgMeta || {},
    component: () => import("/app/src/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: "help___en",
    path: "/help",
    component: () => import("/app/src/pages/help.vue").then(m => m.default || m),
    children: [
  {
    name: "help-slug___en",
    path: ":slug(.*)*",
    meta: _91_46_46_46slug_93FtFwvw1MCBMeta || {},
    component: () => import("/app/src/pages/help/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "help-contact___en",
    path: "contact",
    component: () => import("/app/src/pages/help/contact.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "help___id",
    path: "/id/help",
    component: () => import("/app/src/pages/help.vue").then(m => m.default || m),
    children: [
  {
    name: "help-slug___id",
    path: ":slug(.*)*",
    meta: _91_46_46_46slug_93FtFwvw1MCBMeta || {},
    component: () => import("/app/src/pages/help/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "help-contact___id",
    path: "contact",
    component: () => import("/app/src/pages/help/contact.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "host___en",
    path: "/host",
    meta: index4fwwccb5ksMeta || {},
    component: () => import("/app/src/pages/host/index.vue").then(m => m.default || m)
  },
  {
    name: "host___id",
    path: "/id/host",
    meta: index4fwwccb5ksMeta || {},
    component: () => import("/app/src/pages/host/index.vue").then(m => m.default || m)
  },
  {
    name: "host-redeem-passes___en",
    path: "/host/redeem-passes",
    meta: redeem_45passesRyMvv4fdBeMeta || {},
    component: () => import("/app/src/pages/host/redeem-passes.vue").then(m => m.default || m)
  },
  {
    name: "host-redeem-passes___id",
    path: "/id/host/redeem-passes",
    meta: redeem_45passesRyMvv4fdBeMeta || {},
    component: () => import("/app/src/pages/host/redeem-passes.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/",
    meta: indexdPwi6hPdMfMeta || {},
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___id",
    path: "/id",
    meta: indexdPwi6hPdMfMeta || {},
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login___en",
    path: "/login",
    meta: index1f3Z4n7k2iMeta || {},
    component: () => import("/app/src/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "login___id",
    path: "/id/login",
    meta: index1f3Z4n7k2iMeta || {},
    component: () => import("/app/src/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: _91sid_93cAifiDcS5qMeta?.name,
    path: "/orders/:sid()",
    meta: _91sid_93cAifiDcS5qMeta || {},
    component: () => import("/app/src/pages/orders/[sid].vue").then(m => m.default || m),
    children: [
  {
    name: "orders-sid-checkout___en",
    path: "checkout",
    component: () => import("/app/src/pages/orders/[sid]/checkout.vue").then(m => m.default || m)
  },
  {
    name: "orders-sid___en",
    path: "",
    component: () => import("/app/src/pages/orders/[sid]/index.vue").then(m => m.default || m)
  },
  {
    name: "orders-sid-personal-information___en",
    path: "personal-information",
    component: () => import("/app/src/pages/orders/[sid]/personal-information.vue").then(m => m.default || m)
  },
  {
    name: "orders-sid-terms___en",
    path: "terms",
    component: () => import("/app/src/pages/orders/[sid]/terms.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91sid_93cAifiDcS5qMeta?.name,
    path: "/id/orders/:sid()",
    meta: _91sid_93cAifiDcS5qMeta || {},
    component: () => import("/app/src/pages/orders/[sid].vue").then(m => m.default || m),
    children: [
  {
    name: "orders-sid-checkout___id",
    path: "checkout",
    component: () => import("/app/src/pages/orders/[sid]/checkout.vue").then(m => m.default || m)
  },
  {
    name: "orders-sid___id",
    path: "",
    component: () => import("/app/src/pages/orders/[sid]/index.vue").then(m => m.default || m)
  },
  {
    name: "orders-sid-personal-information___id",
    path: "personal-information",
    component: () => import("/app/src/pages/orders/[sid]/personal-information.vue").then(m => m.default || m)
  },
  {
    name: "orders-sid-terms___id",
    path: "terms",
    component: () => import("/app/src/pages/orders/[sid]/terms.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "orders-callback___en",
    path: "/orders/callback",
    component: () => import("/app/src/pages/orders/callback.vue").then(m => m.default || m)
  },
  {
    name: "orders-callback___id",
    path: "/id/orders/callback",
    component: () => import("/app/src/pages/orders/callback.vue").then(m => m.default || m)
  },
  {
    name: "orders___en",
    path: "/orders",
    meta: indexRgJBGWiq26Meta || {},
    component: () => import("/app/src/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "orders___id",
    path: "/id/orders",
    meta: indexRgJBGWiq26Meta || {},
    component: () => import("/app/src/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93rmewM08fn2Meta?.name,
    path: "/passes/:id()",
    meta: _91id_93rmewM08fn2Meta || {},
    component: () => import("/app/src/pages/passes/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "passes-id-additional-information___en",
    path: "additional-information",
    meta: additional_45informationSYbLWFmKySMeta || {},
    component: () => import("/app/src/pages/passes/[id]/additional-information.vue").then(m => m.default || m)
  },
  {
    name: "passes-id___en",
    path: "",
    meta: indexExkcY2djeQMeta || {},
    component: () => import("/app/src/pages/passes/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "passes-id-management___en",
    path: "management",
    meta: managementBEQCwIMLURMeta || {},
    component: () => import("/app/src/pages/passes/[id]/management.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91id_93rmewM08fn2Meta?.name,
    path: "/id/passes/:id()",
    meta: _91id_93rmewM08fn2Meta || {},
    component: () => import("/app/src/pages/passes/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "passes-id-additional-information___id",
    path: "additional-information",
    meta: additional_45informationSYbLWFmKySMeta || {},
    component: () => import("/app/src/pages/passes/[id]/additional-information.vue").then(m => m.default || m)
  },
  {
    name: "passes-id___id",
    path: "",
    meta: indexExkcY2djeQMeta || {},
    component: () => import("/app/src/pages/passes/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "passes-id-management___id",
    path: "management",
    meta: managementBEQCwIMLURMeta || {},
    component: () => import("/app/src/pages/passes/[id]/management.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "passes___en",
    path: "/passes",
    meta: indexIMI2zd05arMeta || {},
    component: () => import("/app/src/pages/passes/index.vue").then(m => m.default || m)
  },
  {
    name: "passes___id",
    path: "/id/passes",
    meta: indexIMI2zd05arMeta || {},
    component: () => import("/app/src/pages/passes/index.vue").then(m => m.default || m)
  },
  {
    name: "teapot___en",
    path: "/teapot",
    component: () => import("/app/src/pages/teapot.vue").then(m => m.default || m)
  },
  {
    name: "teapot___id",
    path: "/id/teapot",
    component: () => import("/app/src/pages/teapot.vue").then(m => m.default || m)
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubnO9MU04yTU
  }
]