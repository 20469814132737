import * as Sentry from "@sentry/vue";

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter();
  const {
    public: {
      sentry: { dsn },
      site: { env },
    },
  } = useRuntimeConfig();

  if (!dsn) {
    return;
  }

  const getEnvironment = () => {
    if (env === "production") {
      return "Production";
    }

    if (env === "staging") {
      return "Staging";
    }

    if (import.meta.dev || env === "development") {
      return "Development";
    }

    return env;
  };

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn,
    environment: getEnvironment(),
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],

    // Configure this whole part as you need it!
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      "https://community.lapet.ducc.pw",
      "https://community.furries.id",
    ],

    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
  });
});
