<script setup lang="ts">
const {
  public: {
    site: { env },
  },
} = useRuntimeConfig();

const isProduction = ref(false);

isProduction.value = env === "production";

const logoUrl = computed(() => {
  return isProduction.value
    ? "/images/if-logo.svg"
    : "/images/if-logo-canary.png";
});
</script>

<template>
  <div
    class="flex items-center space-x-2"
    role="img"
    aria-label="Indonesia Furs Community"
  >
    <slot>
      <nuxt-img
        :src="logoUrl"
        sizes="100px"
        class="w-8 h-8"
        alt="Indonesia Furs Logo"
      />

      <span class="text-lg font-display font-bold">Community</span>
      <u-badge :ui="{ rounded: 'rounded-full' }" class="uppercase">
        Beta
      </u-badge>
    </slot>
  </div>
</template>
