export default defineNuxtRouteMiddleware(async (to) => {
  // server side
  if (process.server) {
    const accessToken = useCookie("access_token");
    const refreshToken = useCookie("refresh_token");

    if (
      !accessToken.value &&
      !refreshToken.value &&
      to.meta.auth === true // check if protected route
    ) {
      return navigateTo("/api/auth/login", { external: true });
    }
  }

  // client side
  if (import.meta.client) {
    const { status } = useSession();

    if (
      status.value === SessionStatus.UNAUTHENTICATED &&
      to.meta.auth === true
    ) {
      return navigateTo("/api/auth/login", { external: true });
    }
  }
});
