<script setup lang="ts">
defineProps({
  showBranding: {
    type: Boolean,
    default: false,
  },
});

const { status: authStatus } = useSession();
const currentPath = computed(() => useRoute().fullPath);
</script>

<template>
  <header class="py-2 px-6 h-16 w-full flex items-center">
    <div
      class="container mx-auto flex items-center space-x-4 justify-between h-full z-10"
    >
      <span class="flex space-x-2">
        <nuxt-link-locale to="/"><branding /></nuxt-link-locale>
      </span>
      <div class="flex justify-end justify-self-end items-center h-full gap-8">
        <!-- <div class="gap-6 items-center hidden lg:flex">
          <toolbar-language-switcher />
        </div> -->

        <client-only>
          <u-skeleton
            v-if="authStatus === SessionStatus.LOADING"
            class="h-8 w-16"
          />
          <user-account-icon
            v-else-if="authStatus === SessionStatus.AUTHENTICATED"
          />
          <u-button
            v-else
            :to="`/api/auth/login?redirect=${currentPath}`"
            external
            >Log in</u-button
          >
        </client-only>
      </div>
    </div>
  </header>
</template>
