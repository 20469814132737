import {
  type QueryClientConfig,
  useQuery,
  useQueryClient,
} from "@tanstack/vue-query";
import { FetchError } from "ofetch";
import { SessionStatus, useSession } from "./useSession.composable";
import type { operations } from "@/schemas/contool";

export const USE_USER_SESSION_QUERY_KEY = "useUser";

export type UserData =
  operations["CurrentUserController_getCurrentUserProfile"]["responses"]["200"]["content"]["application/json"];

export async function invalidateUserData() {
  // Only run this function on the client side lol
  if (import.meta.server) return;

  return useQueryClient().invalidateQueries({
    queryKey: [USE_USER_SESSION_QUERY_KEY],
  });
}

export function useUser(
  forceLogin = false,
  redirect = "/login",
  queryConfig?: QueryClientConfig,
) {
  const session = useSession(forceLogin, redirect);

  const userQuery = useQuery<UserData>({
    queryKey: [USE_USER_SESSION_QUERY_KEY],
    queryFn: async () => {
      try {
        const user = await $fetch<UserData>("/api/contool/v1/user/me", {
          method: "GET",
        });

        return user;
      } catch (e) {
        if (e instanceof FetchError) {
          switch (e.status) {
            case 401:
              if (forceLogin) {
                navigateTo(`${redirect}?redirect=${window.location.pathname}`, {
                  external: true,
                });
              }

            // no break
            // eslint-disable-next-line no-fallthrough
            case 403:
            default:
              throw new Error("Unexpected response fetching user data");
          }
        } else {
          throw e;
        }
      }
    },

    enabled: session.status.value === SessionStatus.AUTHENTICATED,
    staleTime: 1000 * 60 * 15,
    refetchInterval: 1000 * 60 * 5,

    ...queryConfig,
  });
  if (userQuery.isError.value) {
    console.error(
      "Unexpected response fetching user data",
      userQuery.error.value,
    );
  }

  return {
    user: userQuery.data as Ref<UserData>,
    authStatus: session.status,

    refetch: userQuery.refetch,
    isLoading: userQuery.isLoading as Ref<boolean>,
    isLoadingError: userQuery.isLoadingError,
    isPending: userQuery.isPending,

    query: userQuery,
  };
}
